@tailwind base;
@tailwind components;
@tailwind utilities;

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: #1a202c; /* bg-gray-900 */
    border-radius: 8px;
    padding: 20px;
    max-width: 90%;
    width: 500px;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
}

@media (min-width: 768px) {
    .Modal {
        width: 600px;
    }
}

@media (min-width: 1024px) {
    .Modal {
        width: 700px;
    }
}

.main-container {
    padding-top: 64px;
    background-color: #2d3748;
    min-height: 100vh;
    box-sizing: border-box;
}

.input,
.textarea,
.select {
    border: 1px solid #4a5568;
}
